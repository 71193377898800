import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProjectHeader from "../components/project-header"
import ProjectBlurb from "../components/project-blurb"


const info = {
    references: [
        {
            head: "Engineering Reference",
            name: "Dale Buys",
            employer: "Reichhhardt & Ebe, P.E.",
        },
        {
            head: "Client Reference",
            name: "Dave Schoonover",
            title: "Public Works Director",
            employer: "City of Everson",
        },
    ],
}

const Page = () => {
    const data = useStaticQuery(graphql`
      query {
        header: file(relativePath: { eq: "project-header--everson.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mapp: file(relativePath: { eq: "map--everson.png" }) {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return (
        <Layout hideMenu={true}>
          <SEO title="City of Everson" />
          <ProjectHeader
            client="City of Everson"
            project="Lincoln Street / Kirsch Drive"
            image={data?.header?.childImageSharp?.fluid}
          />
          <ProjectBlurb data={info} mapImage={data.mapp.childImageSharp.fluid}>
            <p>
              The Lincoln Street Phase 1, 2 and Kirsch Drive Project
              widen the existing roadway; install a bike lane, curb,
              gutter and sidewalk, relocate of street illumination,
              and installation of a storm drainage system.  Phase 2
              will construct a new segment of Lincoln Street linking
              S. Washington Street to Blair Drive. Acquire 7
              commercial parcels and 15 residential parcels that had
              partial acquisition, and 3 donation parcels.  RES Group
              completed the Preliminary Funding Estimate and took the
              project through certification. are proceeding with
              acquiring R/W needed for the project construction. Once
              the acquisition is complete, RES Group NW will take the
              project through WSDOT certification.
            </p>
          </ProjectBlurb>
        </Layout>
    )
}

export default Page
